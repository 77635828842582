///PANORAMICAS
import angulodamandibula from "./Img/angulodamandibula.jpeg";
import angulodamandibulaa from "./Img/angulodamandibulaa.jpg";
import basedamandibula from "./Img/basedamandibula.jpeg";
import basedamandibulaa from "./Img/basedamandibulaa.jpg";
import imagemfantasmapd from "./Img/imagemfantasmapd.jpeg";
import imagemfantasmapdd from "./Img/imagemfantasmapdd.jpg";
import fissurapterigomaxilar from "./Img/fissurapterigomaxilar.jpeg";
import fissurapterigomaxilarr from "./Img/fissurapterigomaxilarr.jpg";
import palatoduro from "./Img/palatoduro.jpeg";
import palatoduroo from "./Img/palatoduroo.jpg";
import arcozigomatico from "./Img/arcozigomatico.jpeg";
import arcozigomaticoo from "./Img/arcozigomaticoo.jpg";
import canalmandibular from "./Img/canalmandibular.jpeg";
import canalmandibularr from "./Img/canalmandibularr.jpg";
import conchainferior from "./Img/conchainferior.jpeg";
import conchainferiorr from "./Img/conchainferiorr.jpg";
import espinhanasalanterior from "./Img/espinhanasalanterior.jpeg";
import espinhanasalanteriorr from "./Img/espinhanasalanteriorr.jpg";
import extensaoalveolardoseiomaxilar from "./Img/extensaoalveolardoseiomaxilar.jpeg";
import extensaoalveolardoseiomaxilarr from "./Img/extensaoalveolardoseiomaxilarr.jpg";
import foramemandibular from "./Img/foramemandibular.jpeg";
import foramemandibularr from "./Img/foramemandibularr.jpg";
import foramementoniano from "./Img/foramementoniano.jpeg";
import foramementonianoo from "./Img/foramementonianoo.jpg";
import fossamandibular from "./Img/fossamandibular.jpeg";
import fossamandibularr from "./Img/fossamandibularr.jpg";
import ossohioide from "./Img/ossohioide.jpeg";
import ossohioidee from "./Img/ossohioidee.jpg";
import ossozigomatico from "./Img/ossozigomatico.jpeg";
import ossozigomaticoo from "./Img/ossozigomaticoo.jpg";
import processocoronoide from "./Img/processocoronoide.jpeg";
import processocoronoidee from "./Img/processocoronoidee.jpg";
///PERIAPICAL
import septonasall from "./Img/septonasall.jpg";
import septonasal from "./Img/septonasal.jpg";
import arcozigomaticomolar from "./Img/arcozigomaticomolar.jpg";
import arcozigomaticomolarr from "./Img/arcozigomaticomolarr.jpg";
import assoalhodafossanasal from "./Img/assoalhodafossanasal.jpg";
import assoalhodafossanasall from "./Img/assoalhodafossanasall.jpg";
import assoalhodoseiomaxilar from "./Img/assoalhodoseiomaxilar.jpg";
import assoalhodoseiomaxilarr from "./Img/assoalhodoseiomaxilarr.jpg";
import conchanasalinferior from "./Img/conchanasalinferior.jpg";
import conchanasalinferiorr from "./Img/conchanasalinferiorr.jpg";
import espinhanasalanteriorperiapical from "./Img/espinhanasalanteriorperiapical.jpg";
import espinhanasalanteriorrperiapical from "./Img/espinhanasalanteriorrperiapical.jpg";
import extensaoalveolardoseiomolar from "./Img/extensaoalveolardoseiomolar.jpg";
import extensaoalveolardoseiomolarr from "./Img/extensaoalveolardoseiomolarr.jpg";
import suturapalatinainci from "./Img/suturapalatina.jpg";
import suturapalatinaincii from "./Img/suturapalatinaa.jpg";
import torusmandibularinci from "./Img/torusmandibular.jpg";
import torusmandibularincii from "./Img/torusmandibularr.jpg";
import yinvetidodeenniscani from "./Img/yinvertidodeennis.jpg";
import yinvetidodeenniscanii from "./Img/yinvertidodeenniss.jpg";
import tuberdamaxilamola from "./Img/tuberdamaxila.jpg";
import tuberdamaxilamolaa from "./Img/tuberdamaxilaa.jpg";
import septosinusalmola from "./Img/septosinusal.jpg";
import septosinusalmolaa from "./Img/septosinusall.jpg";
import seiomaxilarpre from "./Img/seiomaxilar.jpg";
import seiomaxilarpree from "./Img/seiomaxilarr.jpg";
import protuberanciamentualinci from "./Img/protuberanciamentual.jpg";
import protuberanciamentualincii from "./Img/protuberanciamentuall.jpg";
import parededoforameincisivoinci from "./Img/paredesdocanalincisivo.jpg";
import parededoforameincisivoincii from "./Img/paredesdocanalincisivoo.jpg";
import ossozigomaticopre from "./Img/ossozigomaticopre.jpg";
import ossozigomaticopree from "./Img/ossozigomaticopree.jpg";
import hamulopterigoideo from "./Img/hamulopterigoideo.jpg";
import hamulopterigoideoo from "./Img/hamulopterigoideoo.jpg";
import fossasnasaisinci from "./Img/fossasnasais.jpg";
import fossasnasaisincii from "./Img/fossasnasaiss.jpg";
import fossacaninacani from "./Img/fossacanina.jpg";
import fossacaninacanii from "./Img/fossacaninaa.jpg";
import foramementualcani from "./Img/foramementuall.jpg";
import foramementualcanii from "./Img/foramementuall.jpg";
import foraminalingualinci from "./Img/foraminalingual.jpg";
import foraminalingualincii from "./Img/foraminalinguall.jpg";
import extensaoalveolarnotuber from "./Img/extensaoalveolarnotuber.jpg";
import extensaoalveolarnotuberr from "./Img/extensaoalveolarnotuberr.jpg";

// import basedamandibulaperiapical from "./Img/basedamandibulaperiapical.jpg";
//TOMOGRAFIAS
import processocoronoidedamandibulaaxiall from "./Img/processocoronoidedamandibulaaxiall.jpg";
import processocoronoidedamandibulacoronall from "./Img/processocoronoidedamandibulacoronall.jpg";
import processocoronoidedamandibulasagitall from "./Img/processocoronoidedamandibulasagitall.jpg";
import ossohioideaxiall from "./Img/ossohioideaxiall.jpg";
import ossohioidecoronall from "./Img/ossohioidecoronall.jpg";
import ossohioidesagitall from "./Img/ossohioidesagitall.jpg";
import forameincisicosagitall from "./Img/forameincisicosagitall.jpg";
import forameincisivoaxiall from "./Img/forameincisivoaxiall.jpg";
import forameincisivocoronall from "./Img/forameincisivocoronall.jpg";
import eminenciaarticularaxiall from "./Img/eminenciaarticularaxiall.jpg";
import eminenciaarticularcoronall from "./Img/eminenciaarticularcoronall.jpg";
import eminenciaarticularsagitall from "./Img/eminenciaarticularsagitall.jpg";
import espinhanasalanterioraxiall from "./Img/espinhanasalanterioraxiall.jpg";
import espinhanasalanteriorcoronal from "./Img/espinhanasalanteriorcoronal.jpg";
import conchasnasaismediaaxiall from "./Img/conchasnasaismediaaxiall.jpg";
import conchasnasaismediacoronall from "./Img/conchasnasaismediacoronall.jpg";
import conchasnasaismediasagitall from "./Img/conchasnasaismediasagitall.jpg";
import celulasdomastoidecoronall from "./Img/celulasdomastoidecoronall.jpg";
import celulasdomastoideaxiall from "./Img/celulasdomastoidee.jpg";
import celulasdomastoidesagitall from "./Img/celulasdomastoidesagitall.jpg";
import arcozigomaticoaxiall from "./Img/arcozigomaticoaxiall.jpg";
import arcozigomaticocoronall from "./Img/arcozigomaticocoronall.jpg";
import arcozigomaticosagitall from "./Img/arcozigomaticosagitall.jpg";
import seiofrontaltomo from "./Img/seiofrontaltomo.jfif";
import seiofrontalcoronaltomo from "./Img/seiofrontalcoronaltomo.jfif";
import seiofrontalaxialtomo from "./Img/seiofrontalaxialtomo.jfif";
import seioesfenoidalsagital from "./Img/seioesfenoidalsagital.jfif";
import seioesfenoidalaxial from "./Img/seioesfenoidalaxial.jfif";
import seioesfenoidalcoronal from "./Img/seioesfenoidalfrontal.jfif";
import atlasc1axialtomo from "./Img/atlasc1axialtomo.jpg";
import atlasc1coronaltomo from "./Img/atlasc1coronaltomo.jpg";
import atlasc1sagitaltomo from "./Img/atlasc1sagitaltomo.jpg";
import axisc2axialtomo from "./Img/axisc2axialtomo.jpg";
import axisc2coronaltomo from "./Img/axisc2coronaltomo.jpg";
import axisc2sagitaltomo from "./Img/axisc2sagitaltomo.jpg";
import canaldohipoglossoaxialtomo from "./Img/canaldohipoglossoaxialtomo.jpg";
import canaldohipoglossocoronaltomo from "./Img/canaldohipoglossocoronaltomo.jpg";
import canaldohipoglossosagitaltomo from "./Img/canaldohipoglossosagitaltomo.jpg";
import canalmandibularaxialtomo from "./Img/canalmandibularaxialtomo.jpg";
import canalmandibularcoronaltomo from "./Img/canalmandibularcoronaltomo.jpg";
import canalmandibularsagitaltomo from "./Img/canalmandibularsagitaltomo.jpg";
import condiloaxialtomo from "./Img/condiloaxialtomo.jpg";
import condilocoronaltomo from "./Img/condilocoronaltomo.jpg";
import condilooccipitalsagitaltomo from "./Img/condilooccipitalsagitaltomo.jpg";
import condiloocciptalaxialtomo from "./Img/condiloocciptalaxialtomo.jpg";
import condiloocciptalcoronaltomo from "./Img/condiloocciptalcoronaltomo.jpg";
import condilosagitaltomo from "./Img/condilosagitaltomo.jpg";
import espinhagenianaaxialtomo from "./Img/espinhagenianaaxialtomo.jpg";
import espinhagenianacoronaltomo from "./Img/espinhagenianacoronaltomo.jpg";
import espinhagenianasagitaltomo from "./Img/espinhagenianasagitaltomo.jpg";
import foramemandibularaxialtomo from "./Img/foramemandibularaxialtomo.jpg";
import foramemandibularcoronaltomo from "./Img/foramemandibularcoronaltomo.jpg";
import foramemandibularsagitaltomo from "./Img/foramemandibularsagitaltomo.jpg";
import foraminalingualaxialtomo from "./Img/foraminalingualaxialtomo.jpg";
import foraminalingualcoronaltomo from "./Img/foraminalingualcoronaltomo.jpg";
import foraminalingualsagitaltomo from "./Img/foraminalingualsagitaltomo.jpg";
import imfdamandibula from "./Img/imfdamandibula.jpg";
import imfdamandibulaa from "./Img/imfdamandibulaa.jpg";

import ameloblastoma from "./Img/ameloblastoma.png";

/////////////////////////////////////////////////////////////////////////////////////////////////PANORAMICAS
export const PanoramicaDados = [
  {
    id: 1,
    name: "Ângulo da Mandíbula",
    img: angulodamandibula,
    imgg: angulodamandibulaa,
    texto:
      "O ângulo da mandíbula é a região onde a mandíbula se curva em direção ao crânio. Ele é formado pela junção da borda inferior da mandíbula com a borda posterior da mandíbula. Na radiologia odontológica, o ângulo da mandíbula pode ser visto nas radiografias panorâmicas como uma estrutura radiopaca em forma de V. É importante para a avaliação da anatomia da mandíbula e para a identificação de possíveis alterações na estrutura óssea. ",
    corte: "Panoramica",
    info: "",
  },
  {
    id: 8,
    name: "Arco Zigomático",
    img: arcozigomatico,
    imgg: arcozigomaticoo,
    texto:
      "O arco zigomático é uma estrutura óssea que faz parte do crânio humano e fica localizado na região das bochechas, próximo aos olhos. Ele é formado pela união do osso zigomático com o osso temporal e ajuda a dar forma e sustentação ao rosto. ",
    corte: "Panoramico",
    info: "",
  },
  {
    id: 2,
    name: "Base da Mandíbula",
    img: basedamandibula,
    imgg: basedamandibulaa,
    texto:
      "A base da mandíbula é uma estrutura óssea importante da face, localizada na região inferior da mandíbula, que tem como principal função sustentar os dentes inferiores e assegurar a integridade da articulação temporomandibular (ATM).",
    corte: "Panoramico",
    info: "",
  },
  {
    id: 9,
    name: "Canal Mandibular",
    img: canalmandibular,
    imgg: canalmandibularr,
    texto:
      "O canal mandibular é um canal ósseo localizado na mandíbula, que abriga o nervo alveolar inferior, responsável por transmitir a sensibilidade dos dentes inferiores e mento. ",
    corte: "",
    info: "",
  },
  {
    id: 10,
    name: "Concha Nasal Inferior",
    img: conchainferior,
    imgg: conchainferiorr,
    texto:
      "A concha inferior é uma estrutura óssea curva e fina localizada no interior do osso maxilar inferior, que ajuda a dar forma e sustentação à mandíbula. Ela também pode ser chamada de lâmina dura e aparece nas radiografias panorâmicas como uma linha curva que acompanha a base dos dentes inferiores. ",

    corte: "",
    info: "",
  },
  {
    id: 11,
    name: "Espinha Nasal Anterior",
    img: espinhanasalanterior,
    imgg: espinhanasalanteriorr,
    texto:
      "A espinha nasal anterior é uma pequena protuberância óssea localizada na linha média da face, na região do nariz. Na radiologia odontológica, ela é visível nas radiografias panorâmicas e ajuda a fornecer referências anatômicas para a avaliação dos dentes e das estruturas adjacentes. A espinha nasal anterior pode ser utilizada como um ponto de referência para a medição de outros elementos do rosto. ",
    corte: "",
    info: "",
  },
  {
    id: 12,
    name: "Extensão Alveolar do Seio Maxilar",
    img: extensaoalveolardoseiomaxilar,
    imgg: extensaoalveolardoseiomaxilarr,
    texto:
      "A extensão alveolar do seio maxilar é uma área de espessamento ósseo que se projeta para dentro do seio maxilar a partir da parede lateral da cavidade nasal. Na radiologia odontológica, essa projeção óssea pode ser vista nas radiografias panorâmicas como uma área radiopaca acima do ápice dos molares superiores. A extensão alveolar do seio maxilar pode afetar a colocação de implantes dentários e a realização de procedimentos odontológicos nessa região.",
    corte: "",
    info: "",
  },

  {
    id: 4,
    name: "Fissura Pterigomaxilar",
    img: fissurapterigomaxilar,
    imgg: fissurapterigomaxilarr,
    texto:
      "Os nervos alveolares posterior e superior passam pela fissura pterigomandibular, que continuam para a fossa infratemporal",
    corte: "Panoramico",
    info: "",
  },
  {
    id: 6,
    name: "Fossa Mandibular",
    img: fossamandibular,
    imgg: fossamandibularr,
    texto:
      "A fossa Mandibular é uma dos 5 principais estruturas que formam a ATM, juntamente com o disco articular, capsula articular, cabeca da mandibula, ligamentos adjacentes",
    corte: "Panoramico",
    info: "",
  },

  {
    id: 13,
    name: "Forame Mandibular",
    img: foramemandibular,
    imgg: foramemandibularr,
    texto:
      "O forame mandibular é uma abertura óssea localizada na mandíbula que permite a passagem do nervo alveolar inferior e dos vasos sanguíneos responsáveis pela irrigação da mandíbula. É visto nas radiografias panorâmicas como uma estrutura radiopaca circular ou oval. ",
    corte: "",
    info: "",
  },
  {
    id: 14,
    name: "Forame Mentoniano",
    img: foramementoniano,
    imgg: foramementonianoo,
    texto:
      "O forame mentoniano é uma abertura óssea localizada na mandíbula, na região do mento, que permite a passagem do nervo mentoniano. ",
    corte: "",
    info: "",
  },
  {
    id: 15,
    name: "Fossa Mandibular",
    img: fossamandibular,
    imgg: fossamandibularr,
    texto:
      "A fossa mandibular é uma depressão óssea localizada na base do crânio, na região da mandíbula. Ela é uma articulação que conecta a mandíbula com o crânio, permitindo a abertura e o fechamento da boca e a realização dos movimentos da mandíbula. Na radiologia odontológica, a fossa mandibular pode ser vista nas radiografias panorâmicas como uma área radiolúcida na base do crânio. Ela é importante para a avaliação da anatomia da mandíbula. ",
    corte: "",
    info: "",
  },
  {
    id: 7,
    name: "Imagem Fantasma do Palato duro",
    img: imagemfantasmapd,
    imgg: imagemfantasmapdd,
    texto:
      "A imagem fantasma do palato duro é uma duplicidade de imagem na radiografia panorâmica causada pela reflexão da radiação absorvida pelo osso do palato duro. Para minimizar esse efeito, é recomendado que o paciente pressione a língua contra o palato durante a tomada da radiografia. ",
    corte: "Panoramico",
    info: "",
  },
  {
    id: 72,
    name: "Imagem Fantasma da mandíbula",
    img: imfdamandibula,
    imgg: imfdamandibulaa,
    texto:
      "A imagem fantasma é uma artefato radiográfico que pode ocorrer em radiografias panorâmicas. A sobreposição pode criar uma imagem secundária (fantasma) da estrutura, deslocada em relação à posição original. A imagem fantasma pode causar confusão no diagnóstico radiológico e prejudicar a interpretação das imagens.",
    corte: "Panoramico",
    info: "",
  },
  {
    id: 16,
    name: "Osso Hioide",
    img: ossohioide,
    imgg: ossohioidee,
    texto:
      "O osso hioide é um osso pequeno em forma de ferradura, localizado no pescoço abaixo da mandíbula e atrás da língua. Ele não está ligado a nenhum outro osso e é importante para a sustentação e movimentação da língua, além de ser relevante para a respiração por estar entre a laringe e a base da língua. ",
    corte: "",
    info: "",
  },
  {
    id: 17,
    name: "Osso Zigomático",
    img: ossozigomatico,
    imgg: ossozigomaticoo,
    texto: `O osso zigomático é um osso facial que faz parte da região malar, também conhecida como maçã do rosto. Ele é formado por uma projeção óssea que se estende lateralmente a partir do crânio, e está localizado na região da bochecha. Na radiologia odontológica, o osso zigomático pode ser visto nas radiografias panorâmicas como uma estrutura radiopaca em forma de "L" invertido. Ele é importante para a avaliação da anatomia da face e para a realização de procedimentos odontológicos nessa região. `,
    corte: "",
    info: "",
  },
  {
    id: 3,
    name: "Palato Duro",
    img: palatoduro,
    imgg: palatoduroo,
    texto:
      "Normalmente cortical bem espessa, facil de identificar em qualquer corte e tomara radiologica",
    corte: "Panoramico",
    info: "",
  },
  {
    id: 18,
    name: "Processo Coronoide",
    img: processocoronoide,
    imgg: processocoronoidee,
    texto:
      "O processo coronoide é uma projeção óssea localizada na parte anterior da mandíbula, próximo à articulação temporomandibular. Ele serve como ponto de fixação para os músculos temporais, que são responsáveis pela movimentação da mandíbula. Na radiologia odontológica, o processo coronoide pode ser visto nas radiografias panorâmicas como uma estrutura radiopaca triangular na parte anterior da mandíbula. Ele é importante para a avaliação da anatomia da mandíbula e para a identificação de possíveis alterações na articulação temporomandibular. ",
    corte: "",
    info: "",
  },
];

////////////////////////////////////////////////////////////////////////////////////////TOMOGRAFIAS
export const Tomografias = [
  {
    id: 1,
    name: "Atlas C1",
    img: [atlasc1sagitaltomo, atlasc1axialtomo, atlasc1coronaltomo],
    texto: " ",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "",
  },

  {
    id: 2,
    name: "Axís C2",
    img: [axisc2axialtomo, axisc2coronaltomo, axisc2sagitaltomo],
    texto: " ",
    corte: ["Axial", "Coronal", "Sagital"],
    info: "",
  },

  {
    id: 10,
    name: "Arco Zigomático",
    img: [arcozigomaticosagitall, arcozigomaticoaxiall, arcozigomaticocoronall],
    texto:
      "Forma a parte lateral da face humana e faz parte da órbita ocular. Ele é composto pela união do processo temporal do osso temporal e do processo zigomático do osso maxilar.",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
  {
    id: 9,
    name: "Células do Mastóide",
    img: [
      celulasdomastoidesagitall,
      celulasdomastoideaxiall,
      celulasdomastoidecoronall,
    ],
    texto:
      "As células mastoides são pequenas cavidades cheias de ar localizadas na porção posterior do osso temporal do crânio, atrás do ouvido. Essas células são revestidas por uma membrana mucosa que secreta muco para lubrificar e proteger o ouvido médio.",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
  {
    id: 8,
    name: "Concha Nasal Medial",
    img: [
      conchasnasaismediasagitall,
      conchasnasaismediaaxiall,
      conchasnasaismediacoronall,
    ],
    texto:
      "Ela é formada por um osso laminar delgado e coberta por uma mucosa respiratória. Alterações na concha nasal medial, como hipertrofia, podem causar obstrução nasal e dificuldade respiratória.",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
  {
    id: 4,
    name: "Canal Mandibular",
    img: [
      canalmandibularaxialtomo,
      canalmandibularcoronaltomo,
      canalmandibularsagitaltomo,
    ],
    texto: " ",
    corte: ["Axial", "Coronal", "Sagital"],
    info: "",
  },

  {
    id: 5,
    name: "Cabeça da Mandíbula",
    img: [condiloaxialtomo, condilocoronaltomo, condilosagitaltomo],
    texto: " ",
    corte: ["Axial", "Coronal", "Sagital"],
    info: "",
  },
  {
    id: 3,
    name: "Canal do Hipoglosso",
    img: [
      canaldohipoglossoaxialtomo,
      canaldohipoglossocoronaltomo,
      canaldohipoglossosagitaltomo,
    ],
    texto:
      "Localizado nas margens do forame magno. é uma passagem curta e frequentemente bipartida que permanece oculta acima da parte anterior de cada côndilo do occiptal.",
    corte: ["Axial", "Coronal", "Sagital"],
    info: "",
  },

  {
    id: 6,
    name: "Condilo Occipital",
    img: [
      condiloocciptalaxialtomo,
      condiloocciptalcoronaltomo,
      condilooccipitalsagitaltomo,
    ],
    texto: " ",
    corte: ["Axial", "Coronal", "Sagital"],
    info: "",
  },

  {
    id: 7,
    name: "Espinha Geniana",
    img: [
      espinhagenianaaxialtomo,
      espinhagenianacoronaltomo,
      espinhagenianasagitaltomo,
    ],
    texto: " ",
    corte: ["Axial", "Coronal", "Sagital"],
    info: "",
  },

  {
    id: 11,
    name: "Eminência Articular",
    img: [
      eminenciaarticularsagitall,
      eminenciaarticularaxiall,
      eminenciaarticularcoronall,
    ],
    texto:
      "A eminência articular é dividida em duas áreas: a superfície convexa superior, que se articula com a fossa mandibular do crânio, e a superfície plana inferior, que é o ponto de inserção dos músculos da mastigação. Alterações na eminência articular, como degeneração da cartilagem, trauma ou doenças degenerativas, podem levar a problemas na ATM e dificuldade de abrir e fechar a boca, além de dor e desconforto na área da mandíbula e face.",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
  {
    id: 12,
    name: "Espinha Nasal Anterior",
    img: [
      espinhanasalanterioraxiall,
      espinhanasalanterioraxiall,
      espinhanasalanteriorcoronal,
    ],
    texto:
      "A forma e o tamanho da espinha nasal podem variar entre os indivíduos e em algumas condições médicas, como fraturas nasais, síndrome de Crouzon ou outras anomalias craniofaciais, pode ocorrer deformidades ou assimetrias na espinha nasal.",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
  {
    id: 13,
    name: "Forame Mandibular",
    img: [
      foramemandibularaxialtomo,
      foramemandibularcoronaltomo,
      foramemandibularsagitaltomo,
    ],
    texto: " ",
    corte: ["Axial", "Coronal", "Sagital"],
    info: "",
  },

  {
    id: 14,
    name: "Foramina Lingual",
    img: [
      foraminalingualaxialtomo,
      foraminalingualcoronaltomo,
      foraminalingualsagitaltomo,
    ],
    texto: " ",
    corte: ["Axial", "Coronal", "Sagital"],
    info: "",
  },
  {
    id: 15,
    name: "Forame Incisivo",
    img: [forameincisicosagitall, forameincisivoaxiall, forameincisivocoronall],
    texto:
      "O forame incisivo é uma abertura localizada na linha média do osso maxilar anterior, que permite a passagem do nervo nasopalatino e vasos sanguíneos para a região dos incisivos superiores, lábio superior e gengiva.",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
  {
    id: 16,
    name: "Osso Hioide",
    img: [ossohioidesagitall, ossohioideaxiall, ossohioidecoronall],
    texto:
      "O osso hioide é um osso pequeno em forma de ferradura, localizado no pescoço abaixo da mandíbula e atrás da língua. Ele não está ligado a nenhum outro osso e é importante para a sustentação e movimentação da língua, além de ser relevante para a respiração por estar entre a laringe e a base da língua.",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
  {
    id: 17,
    name: "Processo Coronoide",
    img: [
      processocoronoidedamandibulasagitall,
      processocoronoidedamandibulaaxiall,
      processocoronoidedamandibulacoronall,
    ],
    texto:
      "O processo coronoide serve como ponto de ancoragem para vários músculos da mandíbula, incluindo o músculo temporal e o músculo masseter. Alterações no tamanho ou forma do processo coronoide podem ser indicativas de doenças como a síndrome da apneia do sono ou a doença de Paget, uma doença óssea que afeta a remodelação óssea.",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
  {
    id: 18,
    name: "Seio Esfenoidal",
    img: [seioesfenoidalsagital, seioesfenoidalaxial, seioesfenoidalcoronal],
    texto:
      "O seio esfenoidal é uma cavidade encontrada no corpo do osso esfenoide. Seu grau de pneumatização é variável, podendo abranger todo o corpo do osso esfenoide. Essa cavidade está localizada logo abaixo da sela turca, que aloja a glândula hipófise.",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },

  {
    id: 19,
    name: "Seio Frontal",

    img: [seiofrontaltomo, seiofrontalaxialtomo, seiofrontalcoronaltomo],
    texto:
      "O seio frontal é uma cavidade localizada dentro do osso frontal do crânio, acima dos olhos e entre as sobrancelhas. É um dos quatro seios paranasais (junto com o seio maxilar, etmoidal e esfenoidal), que são espaços cheios de ar que se comunicam com as cavidades nasais. O seio frontal desempenha um papel importante na redução do peso do crânio e na produção de muco que ajuda a proteger e umedecer as vias respiratórias. ",
    corte: ["Sagital", "Axial", "Coronal"],
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
];

/////////////////////////////////////////////////////////////////////////////////////////////////LESOES E ALTERAÇOES
export const LesoeseAlteracoesDados = [
  {
    id: 1,
    name: "Cigarro2",
    img: angulodamandibula,
    texto: "Facil de identificar em qualquer corte e tomara radiologica",
    corte: "Panoramica",
    info: "Facil de identificar em qualquer corte e tomara radiologica",
  },
];

/////////////////////////////////////////////////////////////////////////////////////////////////PERIAPICAL

export const PeriapicalDados = [
  {
    id: 2,
    name: "Arco Zigomático",
    img: arcozigomaticomolar,
    imgg: arcozigomaticomolarr,
    texto:
      "O arco zigomático é uma estrutura óssea que faz parte da face humana, localizado na região lateral e superior da cavidade ocular. É formado pela união do processo temporal do osso temporal e do processo zigomático do osso da face, e tem como principal função a proteção do globo ocular e dos músculos envolvidos nos movimentos da mandíbula. ",
    corte: "Periapical",
    info: "Facil de identificar em qualquer corte e tomara radiologica",
    tomada: "Molares",
  },

  {
    id: 3,
    name: "Assoalho da Fossa Nasal",
    img: assoalhodafossanasal,
    imgg: assoalhodafossanasall,
    texto: "Facil de identificar em qualquer corte e tomara radiologica",
    corte: "Periapical",
    info: "Facil de identificar em qualquer corte e tomara radiologica",
    tomada: "Caninos",
  },
  {
    id: 4,
    name: "Assoalho do Seio Maxilar",
    img: assoalhodoseiomaxilar,
    imgg: assoalhodoseiomaxilarr,
    texto: "Facil de identificar em qualquer corte e tomara radiologica",
    corte: "Periapical",
    info: "Facil de identificar em qualquer corte e tomara radiologica",
    tomada: "Caninos",
  },
  {
    id: 91,
    name: "Parede do forame Incisivo",
    img: parededoforameincisivoinci,
    imgg: parededoforameincisivoincii,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Incisivos",
  },
  {
    id: 5,
    name: "Concha Nasal Inferior",
    img: conchanasalinferior,
    imgg: conchanasalinferiorr,
    texto: "Facil de identificar em qualquer corte e tomara radiologica",
    corte: "Periapical",
    info: "Facil de identificar em qualquer corte e tomara radiologica",
    tomada: "Incisivos",
  },
  {
    id: 6,
    name: "Espinha Nasal Anterior",
    img: espinhanasalanteriorperiapical,
    imgg: espinhanasalanteriorrperiapical,
    texto: "Facil de identificar em qualquer corte e tomara radiologica",
    corte: "Periapical",
    info: "Facil de identificar em qualquer corte e tomara radiologica",
    tomada: "Incisivos",
  },
  {
    id: 410,
    name: "Extensão Alveolar do Canal Mandibular",
    img: extensaoalveolarnotuber,
    imgg: extensaoalveolarnotuberr,
    texto:
      "A extensão alveolar do canal mandibular é uma projeção do canal mandibular na região posterior dos molares inferiores.",
    corte: "Panorâmico",
    info: "É importante observar a extensão alveolar do canal mandibular em exames radiográficos para a identificação de possíveis patologias ou complicações durante procedimentos odontológicos.",
    tomada: "Molares",
  },

  {
    id: 7,
    name: "Extensão Alveolar do Seio Maxilar",
    img: extensaoalveolardoseiomolar,
    imgg: extensaoalveolardoseiomolarr,
    texto: "Facil de identificar em qualquer corte e tomara radiologica",
    corte: "Periapical",
    info: "Facil de identificar em qualquer corte e tomara radiologica",
    tomada: "Pre-Molares",
  },
  {
    id: 110,
    name: "Fossa Canina",
    img: fossacaninacani,
    imgg: fossacaninacanii,
    texto:
      "A fossa canina é uma estrutura radiopaca presente na região canina dos maxilares, entre o incisivo lateral e o primeiro pré-molar.",
    corte: "Periapical",
    info: "É importante observar a fossa canina em exames radiográficos para a identificação de possíveis patologias.",
    tomada: "Caninos",
  },
  {
    id: 210,
    name: "Forame Mental",
    img: foramementualcani,
    imgg: foramementualcanii,
    texto:
      "O forame mental é uma abertura presente na região do mento, abaixo do segundo pré-molar inferior.",
    corte: "Panorâmico",
    info: "É importante observar o forame mental em exames radiográficos para a identificação de possíveis patologias ou complicações durante procedimentos odontológicos.",
    tomada: "Caninos",
  },
  {
    id: 310,
    name: "Forame Incisivo",
    img: foraminalingualinci,
    imgg: foraminalingualincii,
    texto:
      "O forame incisivo é uma abertura presente na região anterior do palato duro.",
    corte: "Periapical",
    info: "É importante observar o forame incisivo em exames radiográficos para a identificação de possíveis patologias ou complicações durante procedimentos odontológicos.",
    tomada: "Incisivos",
  },

  {
    id: 2102,
    name: "Fossas Nasais",
    img: fossasnasaisinci,
    imgg: fossasnasaisincii,
    texto:
      "As fossas nasais são estruturas presentes na região nasal, sendo importante observá-las em exames radiográficos da região maxilofacial.",
    corte: "Panorâmico",
    info: "As fossas nasais são importantes para a respiração e para o olfato, podendo ser afetadas por diversas patologias.",
    tomada: "Incisivos",
  },
  {
    id: 11,
    name: "Hamulo Pterigóideo",
    img: hamulopterigoideo,
    imgg: hamulopterigoideoo,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Molares",
  },
  {
    id: 41,
    name: "Y invertido de Ennis",
    img: yinvetidodeenniscani,
    imgg: yinvetidodeenniscanii,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Caninos",
  },

  {
    id: 158,
    name: "Osso Zigomático",
    img: ossozigomaticopre,
    imgg: ossozigomaticopree,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Pre-Molares",
  },
  {
    id: 81,
    name: "Protuberância Mental",
    img: protuberanciamentualinci,
    imgg: protuberanciamentualincii,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Incisivos",
  },

  {
    id: 61,
    name: "Septo Sinusal",
    img: septosinusalmola,
    imgg: septosinusalmolaa,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Molares",
  },
  {
    id: 71,
    name: "Seio Maxilar",
    img: seiomaxilarpre,
    imgg: seiomaxilarpree,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Pre-Molares",
  },

  {
    id: 21,
    name: "Sutura Palatina",
    img: suturapalatinainci,
    imgg: suturapalatinaincii,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Incisivos",
  },

  {
    id: 18782,
    name: "Septo Nasal",
    img: septonasal,
    imgg: septonasall,
    texto: "Facil de identificar em qualquer corte e tomara radiologica",
    corte: "Periapical",
    info: "Facil de identificar em qualquer corte e tomara radiologica",
    tomada: "Incisivos",
  },
  {
    id: 3121,
    name: "Torus Mandibular",
    img: torusmandibularinci,
    imgg: torusmandibularincii,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Incisivos",
  },

  {
    id: 5311,
    name: "Tuberosidade Maxilar",
    img: tuberdamaxilamola,
    imgg: tuberdamaxilamolaa,
    texto: "",
    corte: "Periapical",
    info: "",
    tomada: "Molares",
  },
];


export const Lesa = [

  {
    id: 2,
    name: "Ameloblastoma Unicístico",
    densidade: ["radiolucida", "hipodensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "associado ao elemento não irrompido",
    
    expansãoerosãoossea1: true,
    deslocamentodedentes1: true,
    reabsorçãoradicular1: true,
    dentenirrompido1: true,
    img: ameloblastoma,
    diagdif: [
      "Cisto Dentígero",

      "Ameloblastoma Sólido",

      "Tumor Odontogênico Queratocístico",

      "Lesão Central de Células Gigantes",

      "Mixoma.",
    ],
  }
]

export const Les = [
  {
    id: 110,
    name: "Cisto Radicular",
    densidade: ["radiolucida", "hipodensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [],
    deslocamentodedentes: "nao",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "associado ao elemento não irrompido",
  },
  {
    id: 1,
    name: "Ameloblastoma Sólido",
    densidade: ["radiolucida", "hipodensa"],
    locular: "multilocular",
    margem: "de margem irregular",
    definição: "bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "associado ao elemento não irrompido",
  },
  {
    id: 2,
    name: "Ameloblastoma Unicístico",
    densidade: ["radiolucida", "hipodensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "associado ao elemento não irrompido",
    multilocular1: "multilocular",
    margem1: "irregular",
    definição1: "bem definida",
    cortical1: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea1: true,
    deslocamentodedentes1: true,
    reabsorçãoradicular1: true,
    dentenirrompido1: true,
    img: ameloblastoma,
    diagdif: [
      "Cisto Dentígero",

      "Ameloblastoma Sólido",

      "Tumor Odontogênico Queratocístico",

      "Lesão Central de Células Gigantes",

      "Mixoma.",
    ],
  },
  {
    id: 3,
    name: "Tumor Odontogênico Queratocístico",
    densidade: ["radiolucida", "hipodensa"],
    locular: "unilocular",
    margem: "de margem irregular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "não",
    reabsorçãoradicular: "não",
    dentenirrompido: "não",
  },
  {
    id: 4,
    name: "Tumor Odontogênico Epitelial Calcificante",
    densidade: "misto",
    locular: "multilocular",
    margem: "de margem irregular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "",
  },
  {
    id: 5,
    name: "Tumor Odontogênico Adenomatoide",
    densidade: "misto",
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "associado ao elemento não irrompido",
  },

  {
    id: 6,
    name: "Mixoma Odontogênico",
    densidade: ["radiopaca", "hiperdensa"],
    locular: "multilocular",
    margem: "de margem irregular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular: "não",
    dentenirrompido: "associado ao elemento não irrompido",
  },
  {
    id: 7,
    name: "Cementoblastoma",
    densidade: ["radiopaca", "hiperdensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "não",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "não",
  },
  {
    id: 8,
    name: "Fibroma Ameloblastico",
    densidade: ["mista", "hiperdensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular: "não",
    dentenirrompido: "associado ao elemento não irrompido",
  },
  {
    id: 9,
    name: "Odontoma Composto",
    densidade: ["radiopaca", "hiperdensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: "não",
    deslocamentodedentes: "não",
    reabsorçãoradicular: "não",
    dentenirrompido: "associado ao elemento não irrompido",
  },
  {
    id: 10,
    name: "Odontoma Complexo",
    densidade: ["radiopaca", "hiperdensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: "não",
    deslocamentodedentes: "não",
    reabsorçãoradicular: "não",
    dentenirrompido: "associado ao elemento não irrompido",
  },

  {
    id: 11,
    name: "Tumor Odontogênico Cístico Calcificante",
    densidade: "",
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "não",
  },
  {
    id: 12,
    name: "Cisto Dentígero",
    densidade: ["radiolucida", "hipodensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "associado ao elemento não irrompido",
  },

  {
    id: 13,
    name: "Cisto Odontogênico Glandular",
    densidade: ["radiolucida", "hipodensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: "não",
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "",
    reabsorçãoradicular: "não",
    dentenirrompido: "não",
  },

  {
    id: 14,
    name: "Tumor Odontogênico Adenomatoide",
    densidade: "mista",
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "associado ao elemento não irrompido",
  },
  {
    id: 15,
    name: "Cisto Nasopalatino",
     densidade: ["radiolucida", "hipodensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular: "não",
    dentenirrompido: "não",
  },
  {
    id: 16,
    name: "Cisto Ósseo Simples",
    densidade: ["radiolucida", "hipodensa"],
    locular: "unilocular",
    margem: "de margem irregular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: "não",
    deslocamentodedentes: "não",
    reabsorçãoradicular: "não",
    dentenirrompido: "não",
  },
  {
    id: 17,
    name: "Defeito de Stafne",
    densidade: ["radiolucida", "hipodensa"],
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: "não",
    deslocamentodedentes: "não",
    reabsorçãoradicular: "não",
    dentenirrompido: "não",
  },

  {
    id: 18,
    name: "Lesão Central de Células Gigantes",
    densidade: ["radiolucida", "hipodensa"],
    locular: "multilocular",
    margem: "de margem irregular",
    definição: "Bem definida",
    cortical: "não",
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "deslocando o elemento dentário",
    reabsorçãoradicular:
      "promovendo reabsorção da(s) raíz(es) do(s) elemento(s)",
    dentenirrompido: "não",
  },
  {
    id: 19,
    name: "Fibroma Ossificante Central",
    densidade: "mista",
    locular: "unilocular",
    margem: "de margem regular",
    definição: "Bem definida",
    cortical: [
      "com borda corticalizada",
      "com borda parcialmente corticalizada",
    ],
    expansãoerosãoossea: [
      "causando expansão das corticais",
      "causando expansão e adelgaçamento das corticais",
    ],
    deslocamentodedentes: "não",
    reabsorçãoradicular: "não",
    dentenirrompido: "não",
  },
];
